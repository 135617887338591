import { useEffect } from 'react'

export default function Home() {
  const pathToRedirect = '/catalog'

  useEffect(() => {
    if (pathToRedirect && typeof window !== 'undefined') {
      window.location.href = pathToRedirect
    }
  }, [pathToRedirect])

  return null
}
